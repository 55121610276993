body {
  background-color: #eee;
  font-family: sans-serif;
  height: 100%;
  margin: 0;
  padding: 20px 0;
  width: 100%;
}

a { color: cornflowerblue; }

p.collapse {
  background-color: #eee;
  border: none;
  border-radius: 3px;
margin: 3px 8px 0 0;
padding: 9px;
height: 0;
width: 0;
}
p.collapse > p {
color: #aaa;
margin-top: -14px;
margin-left: -7px;
}
p.collapse_label {
font-size: 20px;
margin: 0;
}
p.collapse_parent,
p.add_new_device {
display: flex;
margin-top: 35px;
}

p.add_new_device > p.collapse_label {
color: #aaa;
}

@page {
size: A4 portrait;
margin: 0;
}

@media print {
body {
  margin-top: 1.6cm;
}
#printButton {
  display: none;
}
.no_print {
  display: none;
}
ul.navbar-nav {
  display: none;
}
}

ul.navbar-nav {
list-style: none;
display: flex;
}
ul.navbar-nav > li > a.nav-link {
text-decoration: none;
color: #444;
font-size: 18px;
padding: 15px;
}

body {
	color: #444;
	font-size: 1rem;
	padding: 0;
}

h1 {
	font-size: 2.25rem;
	margin-bottom: 2.5rem;
	margin-top: 0;
}

img {
	width: auto;
	max-width: 100%;
}

.about-us-image {
	max-width: 800px;
	margin-left: auto;
	margin-right: auto;
}

.container {
	margin: 0 auto;
	padding: 30px 15px;
}

.font18 {
	font-size: 1.125rem;
}

.font21 {
	font-size: 1.25rem;
}

.line-height-larger {
	line-height: 1.5;
}

.red-text {
	color: #ff0000;
}

.mb-0 {
	margin-bottom: 0;
}

.bold {
	font-weight: bold;
}

.text-center {
	text-align: center;
}

@media (min-width: 576px) {
    .container {
        max-width: 540px;
    }
}
@media (min-width: 768px) {
    .container {
        max-width: 720px;
    }
}
@media (min-width: 992px) {
    .container {
        max-width: 960px;
    }
}
@media (min-width: 1200px) {
    .container {
        max-width: 1140px;
    }
}
@media (min-width:1440px) {
	.container {
  		max-width:1380px
	}
}